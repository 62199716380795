@use '~@chewy/kib-foundations/src/typography';

@mixin no-spacing {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.display-1 {
  @include typography.style-as('display-1');
  @include no-spacing;
}

.display-2 {
  @include typography.style-as('display-2');
  @include no-spacing;
}

.display-3 {
  @include typography.style-as('display-3');
  @include no-spacing;
}

.display-4 {
  @include typography.style-as('display-4');
  @include no-spacing;
}

.display-5 {
  @include typography.style-as('display-5');
  @include no-spacing;
}

.display-6 {
  @include typography.style-as('display-6');
  @include no-spacing;
}

.section-1 {
  @include typography.style-as('section-1');
  @include no-spacing;
}

.section-2 {
  @include typography.style-as('section-2');
  @include no-spacing;
}

.section-3 {
  @include typography.style-as('section-3');
  @include no-spacing;
}

.paragraph {
  @include typography.style-as('paragraph');
  @include no-spacing;
}

.paragraph-1 {
  @include typography.style-as('paragraph-1');
  @include no-spacing;
}

.paragraph-2 {
  @include typography.style-as('paragraph-2');
  @include no-spacing;
}

.caption {
  @include typography.style-as('caption');
  @include no-spacing;
}

.button {
  @include typography.style-as('button');
  @include no-spacing;
}

.button-medium {
  @include typography.style-as('button-medium');
  @include no-spacing;
}

.overline {
  @include typography.style-as('overline');
  @include no-spacing;
}

.form-grouping {
  @include typography.style-as('form-grouping');
  @include no-spacing;
}

.form-label {
  @include typography.style-as('form-label');
  @include no-spacing;
}

.form-text {
  @include typography.style-as('form-text');
  @include no-spacing;
}

.form-helper {
  @include typography.style-as('form-helper');
  @include no-spacing;
}

.form-error {
  @include typography.style-as('form-error');
  @include no-spacing;
}

.utility {
  @include typography.style-as('utility');
  @include no-spacing;
}

.utility-small {
  @include typography.style-as('utility-small');
  @include no-spacing;
}

.price-small {
  @include typography.style-as('price-small');
  @include no-spacing;
}

.price-medium {
  @include typography.style-as('price-medium');
  @include no-spacing;
}

.price-large {
  @include typography.style-as('price-large');
  @include no-spacing;
}

.price-x-large {
  @include typography.style-as('price-x-large');
  @include no-spacing;
}

.price-x-small-strikethrough {
  @include typography.style-as('price-x-small-strikethrough');
  @include no-spacing;
}

.price-small-strikethrough {
  @include typography.style-as('price-small-strikethrough');
  @include no-spacing;
}
