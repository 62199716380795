@use '~@chewy/kib-foundations/src/styles/kib-foundations';
@use '~@chewy/kib-foundations/src/elevation';

.card {
  @include kib-foundations.card;
  @include elevation.elevation('minimal');
  border: 1px solid var(--chirp-ui-bg-07, #ffffff);
  overflow: hidden;
  border-radius: 12px;
}
