/* Required global styles (must load first)
<Insert theme here if needed> */
@import '@chewy/kib-normalize/dist/kib-normalize.css';
@import '~@chewy/kib-normalize/dist/styles/theme-base.css';
@import '~@chewy/kib-normalize/dist/styles/theme-base-light.css';

/* Chirp styles goes here */
@import '@chewy/kib-global/dist/kib-global.css';
@import '@chewy/kib-controls-styles/dist/styles/kib-button-new.css';
@import '@chewy/kib-patterns/dist/styles/kib-icon.css';
@import '@chewy/kib-menus-styles/dist/styles/kib-standalone-link.css';
@import '@chewy/kib-menus-styles/dist/styles/kib-breadcrumbs.css';
@import '@chewy/kib-divider-styles/dist/kib-divider.css';
@import '@chewy/kib-label-styles/dist/kib-label.css';
@import '@chewy/kib-transition-styles/dist/kib-transition.css';
@import '@chewy/kib-layout-styles/dist/styles/kib-grid.css';
@import '@chewy/kib-skeleton-styles/dist/styles/kib-skeleton-shape.css';
@import '@chewy/kib-skeleton-styles/dist/styles/kib-skeleton-text.css';
@import '@chewy/kib-behaviors-styles/dist/styles/kib-sticky.css';
@import '@chewy/kib-content-groups-styles/dist/styles/kib-empty-state.css';
@import '@chewy/kib-tag-styles/dist/kib-tag.css';
@import '@chewy/kib-overlays-styles/dist/styles/kib-modal.css';
@import '@chewy/kib-interstitials-styles/dist/styles/kib-spinner.css';
@import '@chewy/kib-fields-styles/dist/styles/kib-input-text.css';
@import '@chewy/kib-fields-styles/dist/styles/kib-input-textarea.css';
@import '@chewy/kib-fields-styles/dist/styles/kib-input-select.css';
@import '@chewy/kib-cropper-styles/dist/kib-cropper.css';
@import '@chewy/kib-chips-styles/dist/styles/kib-chip-input.css';
@import '@chewy/kib-choices-styles/dist/styles/kib-checkbox.css';
@import '@chewy/kib-fields-new-styles/dist/styles/kib-field.css';
@import '@chewy/kib-fields-new-styles/dist/kib-fields-new.css';
@import '@chewy/kib-datepicker-styles/dist/kib-datepicker.css';
@import '@chewy/kib-fields-new-styles/dist/styles/kib-select.css';
@import '@chewy/kib-chips-styles/dist/styles/kib-chip-choice.css';
@import '@chewy/kib-chips-styles/dist/styles/kib-chip-choices.css';
@import '@chewy/kib-progress-new-styles/dist/styles/kib-progress-path.css';
@import '@chewy/kib-interstitials-styles/dist/styles/kib-spinner.css';
@import '@chewy/kib-cards-styles/dist/styles/kib-ingress-card.css';
@import '@chewy/kib-content-groups-styles/dist/styles/kib-tabs-new.css';
@import '@chewy/kib-overlays-styles/dist/styles/kib-sheet.css';
@import '@chewy/kib-menus-styles/dist/styles/kib-pagination-new.css';
@import '@chewy/kib-messaging-styles/dist/styles/kib-snackbar-manager.css';
@import '@chewy/kib-messaging-styles/dist/styles/kib-snackbar.css';
@import '@chewy/kib-transition-styles/dist/kib-transition.css';

/* other */
@import 'reactjs-popup/dist/index.css';
@import 'react-circular-progressbar/dist/styles.css';

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100vh !important;
  overflow-x: hidden;
  background-color: var(--chirp-ui-bg-02, #eeeeee);
}

#root {
  width: 100%;
  height: 100vh;
}
